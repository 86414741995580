import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import AxiosInstance from './services/http-common'
import store from '@/store'
export default {
    install(Vue) {
        // use new Vue instance as an event bus
        const ordersHub = new Vue()
        // every component will use this.$ordersHub to access the event bus
        Vue.prototype.$ordersHub = ordersHub
        // Forward server side SignalR events through $ordersHub, where components will listen to them

        const url = AxiosInstance.defaults.baseURL.replace('api/', '') + 'order-hub'
        const connection = new HubConnectionBuilder()
            .withUrl(url)
            .configureLogging(LogLevel.Information)
            .build()

        Vue.prototype.$signalR = connection

        connection.on('OrderStatusChange', (orderId, boxId, orderProductId, statusId, picked) => {
            ordersHub.$emit('status-changed', { orderId, boxId, orderProductId, statusId, picked })
        })

        connection.on('ProductsUpdate', (products) => {
            ordersHub.$emit('new-products-from-server', { products })
        })

        let startedPromise = null

        var awaiting = false;

        Vue.prototype.$startSignalR = function start() {
            if (connection.state === "Disconnected") {
                startedPromise = connection.start().then(() => {
                    ordersHub.$emit('signal-r-status-changed', true);
                }).catch(err => {
                    AxiosInstance.get("/SMS/SendErrorMessage", {
                        params: {
                            message: "Connection attempt from " + store.state.authUser.username + " failed in vue.js."
                        }
                    }).then(response => {

                    }).catch(error => {

                    })
                    ordersHub.$emit('signal-r-status-changed', false);
                    console.error('Failed to connect with hub', err)
                    return new Promise((resolve, reject) => {
                        if (store.getters['authUser/isLoggedIn']) {
                            if (!awaiting) {
                                setTimeout(() => start().then(resolve).catch(reject), 15000)
                            }
                            awaiting = true;
                        }
                    })
                })
                return startedPromise
            } else {
                ordersHub.$emit('signal-r-status-changed', true);
            }
        }
        connection.onclose(() => start())

        // if (store.getters['authUser/isLoggedIn']) {
        //     start()
        // }

        // this.$startSignalR();
    },
}
