<template>
  <v-container>
    <v-row align="center">
      <span>
        Copyright &copy; {{ currentYear }}
        <a
          id="companyLink"
          target="_blank"
          href="https://www.softclient.com.au/"
          >www.softclient.com.au</a
        >

        all rights reserved.
      </span>
      <v-col class="text-right">
        <!-- <span>Version 21.03.10.0</span> -->
        <span>Version B.E.{{ bVersion }} : F.E.{{ fVersion }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { version } from "../../package";
export default {
  name: "Links",

  data: () => ({
    bVersion: "2022.03.21.00",
     fVersion: version,
    currentYear: new Date().getFullYear(),
  }),
  created() {
  //  this.getVersion();
  },
  methods: {
    getVersion() {
      this.$http.get("/version").then((response) => {
        this.bVersion = response.data.version;
      });
    },
  },
};
</script>

<style lang="sass" scoped>
#companyLink
  color: #fe4a4b !important
  text-decoration: none
</style>
