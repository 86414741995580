// Pathify
import { make } from 'vuex-pathify'

// Data
const state = {
  drawer: null,
  drawerImage: true,
  mini: true,
  items: [
    {
      title: 'Dashboard',
      icon: 'mdi-view-dashboard',
      to: '/',
    },
    // {
    //   title: 'Balance Account',
    //   icon: 'mdi-alpha-b-circle ',
    //   to: '/balance-account',
    // },
    // {
    //   title: 'Events',
    //   icon: 'mdi-alpha-e-circle ',
    //   to: '/events',
    // },
    // {
    //   title: 'Guests',
    //   icon: 'mdi-alpha-g-circle ',
    //   to: '/guests',
    // },
    // {
    //   title: 'Wristbands',
    //   icon: 'mdi-alpha-w-circle ',
    //   to: '/wristbands',
    // },
    // {
    //   title: 'Sponsors',
    //   icon: 'mdi-alpha-s-circle ',
    //   to: '/sponsors',
    // },
    // {
    //   title: 'Products',
    //   icon: 'mdi-alpha-p-circle ',
    //   to: '/products',
    // },
    // {
    //   title: 'Assets',
    //   icon: 'mdi-alpha-a-circle ',
    //   to: '/assets',
    // },
    // {
    //   title: 'Users',
    //   icon: 'mdi-account-group ',
    //   to: '/users/',
    // },
    // {
    //   title: 'Covid Tracking',
    //   icon: 'mdi-list-status ',
    //   to: '/covid-tracking/',
    // },
    // {
    //   title: 'User History',
    //   icon: 'mdi-account-details ',
    //   to: '/user-histories/',
    // },
    // {
    //   title: 'Logs',
    //   icon: 'mdi-post  ',
    //   to: '/logs/',
    // },
  ],
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
  init: async ({ dispatch }) => {
    //
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
