// Imports
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('../layouts/default/Index'),
      children: [
        {
          path: 'member/:id',
          name: 'Attendance',
          component: () => import('../views/Attendance'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '',
          name: 'Dashboard',
          component: () => import('../views/Dashboard'),
          meta: {
            requiresAuth: true,
          },
        },
     
       
    
     
      ],
    },
    {
      path: '/login',
      component: () => import('../layouts/page/Index'),
      children: [
        {
          path: '',
          name: 'Login',
          component: () => import('../views/Login'),
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  // if (to.matched.some(record => record.meta.requiresAuth)) {
  //   if (store.getters['authUser/isLoggedIn']) {
  //     if (hasAccess(to.name)) {
  //       next()
  //     } else {
  //       next('/login')
  //     }
  //   } else {
  //     next('/login')
  //   }
  // } else {
  //   next()
  // }
  next();
})

function hasAccess(name) {
  var permissions = localStorage.getItem('permissions')

  switch (name) {
    case 'Dashboard':
      return true
    case 'Devices':
      return true
    case 'Users':
      return permissions.includes('Permissions.Users.ViewAll')
    case 'Settings':
      return permissions.includes('Permissions.Settings.ViewAll')
    default:
      return false
  }
}

export default router
